export const Images = [
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712224/uvdcvaf7c9rqn8jlnju4.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712224/uvdcvaf7c9rqn8jlnju4.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712224/bdusztm1tpe4tfnrtaos.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712224/bdusztm1tpe4tfnrtaos.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/m0kijl3p5x4rxcvttfmy.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/m0kijl3p5x4rxcvttfmy.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/tul1737v9ru6ihgvrhyu.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/tul1737v9ru6ihgvrhyu.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712225/mjdryedvutm7adlfois6.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712225/mjdryedvutm7adlfois6.jpg",
    },


    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/bz0nlh6uwakjexvdsipr.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712226/bz0nlh6uwakjexvdsipr.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712234/cxsgxoevkbht1etsmmcy.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712234/cxsgxoevkbht1etsmmcy.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712235/tuzwdpsbywevcyrbjok0.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712235/tuzwdpsbywevcyrbjok0.jpg",
    },
    {
        original: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712225/mjdryedvutm7adlfois6.jpg",
        thumbnail: "https://res.cloudinary.com/dg5dkcpkn/image/upload/v1721712225/mjdryedvutm7adlfois6.jpg",
    }
];