export const LaosImages = [
    {
        original: "https://media2.thrillophilia.com/images/photos/000/367/446/original/1614170469_shutterstock_786086242.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/367/446/original/1614170469_shutterstock_786086242.jpg?w=753&h=450&dpr=1.5",
    },
    {
        original: "https://media2.thrillophilia.com/images/photos/000/367/439/original/1614169946_shutterstock_1062273197.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/367/439/original/1614169946_shutterstock_1062273197.jpg?w=753&h=450&dpr=1.5",
    },
    {
        original: "https://media2.thrillophilia.com/images/photos/000/367/510/original/1614174738_1614149383_shutterstock_1462704626.jpg.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/367/510/original/1614174738_1614149383_shutterstock_1462704626.jpg.jpg?w=753&h=450&dpr=1.5",
    },
    {
        original: "https://media2.thrillophilia.com/images/photos/000/367/509/original/1614174714_1614149228_shutterstock_489906367.jpg.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/367/509/original/1614174714_1614149228_shutterstock_489906367.jpg.jpg?w=753&h=450&dpr=1.5",
    },
    {
        original: "https://media2.thrillophilia.com/images/photos/000/373/184/original/1621935231_shutterstock_1177486879.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/373/184/original/1621935231_shutterstock_1177486879.jpg?w=753&h=450&dpr=1.5",
    },

    {
        original: "https://media2.thrillophilia.com/images/photos/000/373/185/original/1621935297_shutterstock_1384011518.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/373/185/original/1621935297_shutterstock_1384011518.jpg?w=753&h=450&dpr=1.5",
    },
    {
        original: "https://media2.thrillophilia.com/images/photos/000/373/186/original/1621935377_shutterstock_393505276.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/373/186/original/1621935377_shutterstock_393505276.jpg?w=753&h=450&dpr=1.5",
    },

    {
        original: "https://media2.thrillophilia.com/images/photos/000/367/463/original/1614171696_shutterstock_1075271321.jpg?w=753&h=450&dpr=1.5",
        thumbnail: "https://media2.thrillophilia.com/images/photos/000/367/463/original/1614171696_shutterstock_1075271321.jpg?w=753&h=450&dpr=1.5",
    },
];