export const Images = [
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730555109/y2jqrnh5bnzoxfimmiiy.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730555109/y2jqrnh5bnzoxfimmiiy.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029929/gawahigrekrmpioldhli.webp",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029929/gawahigrekrmpioldhli.webp",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ger9m0cdytuubcyil2uk.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ger9m0cdytuubcyil2uk.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ynfxpejqn2ysr0q1t3tu.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ynfxpejqn2ysr0q1t3tu.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ntfbhxthuein06iqdtnf.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/ntfbhxthuein06iqdtnf.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/jv8ssruokgawz0cmoddp.webp",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730029928/jv8ssruokgawz0cmoddp.webp",
    }
];