export const Images = [
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240624/WhatsApp_Image_2024-11-22_at_01.23.32_yqrymj.jpg",
        "thumbnail": "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240624/WhatsApp_Image_2024-11-22_at_01.23.32_yqrymj.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.23_fjopij.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.23_fjopij.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.24_uzphb5.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.24_uzphb5.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.13_ijs3qw.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.13_ijs3qw.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.25_1_x6ehfp.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240379/WhatsApp_Image_2024-11-22_at_01.23.25_1_x6ehfp.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240380/WhatsApp_Image_2024-11-22_at_01.23.23_1_dxrsba.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240380/WhatsApp_Image_2024-11-22_at_01.23.23_1_dxrsba.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240453/WhatsApp_Image_2024-11-22_at_01.23.33_n1lxee.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240453/WhatsApp_Image_2024-11-22_at_01.23.33_n1lxee.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240455/WhatsApp_Image_2024-11-22_at_01.23.40_wpz0tf.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1732240455/WhatsApp_Image_2024-11-22_at_01.23.40_wpz0tf.jpg",
    },

];