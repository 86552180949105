export const Images = [
    {
        original: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254046/0e_iokr1b.jpg",
        thumbnail: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254046/0e_iokr1b.jpg",
    },
    {
        original: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712253996/images_xbqbra.jpg",
        thumbnail: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712253996/images_xbqbra.jpg",
    },
    {
        original: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254161/images_pcnhqa.jpg",
        thumbnail: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254161/images_pcnhqa.jpg",
    },
    {
        original: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254168/images_qb0oyt.jpg",
        thumbnail: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254168/images_qb0oyt.jpg",
    },
    {
        original: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254202/ad_utb6cd.jpg",
        thumbnail: "https://res.cloudinary.com/dxhoawdbh/image/upload/v1712254202/ad_utb6cd.jpg",
    },
];